<template>
  <div class="provide-pricing" v-if="isShow">
    <div class="content">
      <div class="cl">
        <div class="fl title">{{$t('Provide Quotation')}}</div>
        <a class="fr btn-close" href="javascript:;" @click="isShow=false">
          <img src="../../../../assets/images/icon/close.png"/>
        </a>
      </div>
      <div class="form-cont">
        <div class="ipt">
          <input type="number" autocomplete="off" :placeholder="amountPlaceholder" v-model.trim="joinPrice"/>
        </div>
        <div class="upload-wrap">
          <upload-file v-model="resourceUrl"/>
        </div>
      </div>
      <div class="actions">
        <button class="button button-primary" type="button" @click="onSubmit">{{$t('Submit')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import UploadFile from '../../../../components/UploadFile';
  import {joinQuote} from '../../../../api/admin-quote';

  export default {
    name: 'ProvidePricing',
    props: {
      quoteId: String,
      currencyOptions: Array,
      billingType: String
    },
    data() {
      return {
        isShow: false,
        joinPrice: '',
        resourceUrl: ''
      };
    },
    computed: {
      amountPlaceholder() {
        let placeholder = this.$t('Please enter the quote amount');
        const current = (this.currencyOptions.find(item => item.code === this.billingType) || {}).name || '';
        if (current) {
          placeholder += `（${this.$t('Currency')}:${current}）`;
        }
        return placeholder;
      }
    },
    components: {UploadFile},
    methods: {
      show() {
        this.isShow = true;
      },
      onSubmit() {
        const joinPrice = this.joinPrice;
        const resourceUrl = this.resourceUrl;

        if (!joinPrice) {
          this.$message.warning(this.$t('Please enter the quote amount'));
          return false;
        }
        if (!resourceUrl) {
          this.$message.warning('請上傳報價文件');
          return false;
        }

        const data = {quoteId: this.quoteId, joinPrice, resourceUrl};
        joinQuote(data).then(res => {
          this.$emit('reload');
          this.$message.success('提交成功');
          this.isShow = false;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .provide-pricing{
    position:fixed;top:0;right:0;bottom:0;left:0;z-index:1800;text-align:center;background-color:rgba(0, 0, 0, 0.5);
    &:after{display:inline-block;height:100%;vertical-align:middle;content:'';}
    .content{
      display:inline-block;width:690px;padding:24px 30px;vertical-align:middle;background-color:#fff;animation-name:zoomIn;animation-duration:.4s;animation-fill-mode:backwards;
      .title{font-size:24px;}
      .btn-close{margin-top:6px;}
    }
    .form-cont{margin-top:24px;padding-top:24px;border-top:1px solid #f4f4f4;}
    .ipt{
      input{
        width:430px;height:48px;padding:0 10px;font-size:15px;border:1px solid #d9d9d9;border-radius:4px;
        &:focus{border-color:#ff6f61;}
      }
      &.w1{width:64px;margin-right:8px;}
    }
    .upload-wrap{margin:16px -30px 0;}
    .actions{margin-top:40px;}
  }
  @keyframes zoomIn{
    0%{opacity:0;transform:scale(0)}
    50%{opacity:1;}
    100%{opacity:1;transform:scale(1)}
  }
</style>
