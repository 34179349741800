<template>
  <div class="main">
    <quote-detail class="detail-infos"
                  :detail="detail"
                  :contacts="contacts"
                  :appellation-options="appellationOptions"
                  :currency-options="currencyOptions"
                  :phone-type-options="phoneTypeOptions"/>
    <div class="actions" v-if="detail.isStatus==='issue'">
      <button class="button button-primary" type="button" @click="showModel">{{$t('Submit Quotation')}}</button>
    </div>
    <provide-pricing :quote-id="quoteId"
                     :currency-options="currencyOptions"
                     :billing-type="detail.billingType"
                     @reload="getDetail"
                     ref="providePricing"/>
  </div>
</template>
<script>
  import QuoteDetail from '../../../components/QuoteDetail';
  import ProvidePricing from './components/ProvidePricing';
  import {getMemberQuoteCount, getQuoteDetail} from '../../../api/admin-quote';
  import {getDictList} from '../../../api/admin-common';

  export default {
    name: 'AdminQuoteDetail',
    data() {
      return {
        quoteId: '',
        detail: {},
        contacts: null,
        appellationOptions: [],
        currencyOptions: [],
        phoneTypeOptions: [],
        quoteAndMember: {
          adminMemberCount: 10,
          adminQuoteCount: 0
        }
      };
    },
    components: {QuoteDetail, ProvidePricing},
    created() {
      this.quoteId = this.$route.params.id;
      this.getDetail();
      this.getDicts();
    },
    methods: {
      getDicts() {
        // 称谓
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });
        // 货币
        getDictList('billingType').then(res => {
          this.currencyOptions = res.value || [];
        });
        getDictList('phoneType').then(res => {
          this.phoneTypeOptions = res.value || [];
        });
      },
      // 获取详情
      getDetail() {
        getQuoteDetail(this.quoteId).then(res => {
          const datas = res.value || {};
          this.detail = datas.quote;
          this.contacts = datas.contacts;
        });
      },
      showModel() {
        this.getMemberQuoteCount();
        if (this.quoteAndMember.adminQuoteCount >= this.quoteAndMember.adminMemberCount) {
          this.$message.warning('本月报价数量已满！请提升会员等级。');
          return false;
        }
        this.$refs.providePricing.show();
      },
      // 获取本月报价数量
      getMemberQuoteCount() {
        getMemberQuoteCount().then(res => {
          const {value} = res;
          this.quoteAndMember = value;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    .detail-infos{padding:0 30px 30px;border:1px solid #f4f4f4;}
    .actions{margin-top:30px;}
  }
</style>
