import request from '@/utils/request';

// 参与报价
export function joinQuote(data) {
  return request({
    url: '/admin/quote/join',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取报价列表
export function getQuoteList(data) {
  return request({
    url: '/admin/quote/list',
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 获取报价详情
export function getQuoteDetail(quoteId) {
  return request({
    url: `/admin/quote/details/${quoteId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 删除参与报价
export function deleteQuote(quoteId) {
  return request({
    url: `/admin/quote/delete/${quoteId}`,
    baseURL: '/v2',
    method: 'post'
  });
}

// 获取商家本月报价数量
export function getMemberQuoteCount() {
  return request({
    url: `/admin/quote/memberQuoteCount`,
    baseURL: '/v2',
    method: 'post'
  });
}
