<template>
  <div class="upload-file">
    <el-progress class="upload-progress" type="circle" color="#ff6f61" :percentage="progress"
                 v-if="showProgress"/>
    <el-upload drag
               :action="action"
               :accept="accept"
               :show-file-list="false"
               :http-request="uploadFile"
               v-else>
      <template v-if="fileUrl">
        <img class="img" :src="fileUrl" v-if="fileType.includes('image')"/>
        <div class="file-name" v-else>{{fileName}}</div>
      </template>
      <template v-else>
        <div class="ico">
          <img class="img" src="../assets/images/icon/upload-red-80.png" alt=""/>
        </div>
        <div class="upload-txt">{{$t('Click or drag and drop files to upload here')}}</div>
        <div class="upload-tip">{{$t('Support extension')}}：{{accept}}</div>
      </template>
    </el-upload>
  </div>
</template>
<script>
  import {upload} from '../api/admin-common';

  export default {
    props: {
      fileTitle: String,
      action: {
        type: String,
        default: '/v2/admin/common/file'
      },
      accept: {
        type: String,
        default: '.pdf'
      }
    },
    name: 'UploadFile',
    data() {
      return {
        showProgress: false,
        progress: 0,
        fileUrl: '',
        fileName: '',
        fileType: ''
      };
    },
    methods: {
      // 覆盖默认的上传行为，可以自定义上传的实现
      uploadFile(param) {
        let formData = new FormData();
        formData.append('file', param.file);
        this.fileName = param.file.name;
        this.fileType = param.file.type;
        this.showProgress = true;
        upload(formData, this.onUploadProgress).then(res => {
          this.fileUrl = res.value.url;
          this.$emit('update:fileTitle', this.fileName);
          this.$emit('input', res.value.url);
          this.showProgress = false;
          this.progress = 0;
        });
      },
      // 上传进度
      onUploadProgress(e) {
        this.progress = Number(((e.loaded / e.total * 100) || 0).toFixed(2));
      }
    }
  };
</script>
<style scoped lang="less">
  .upload-file{
    position:relative;width:430px;height:260px;margin:0 auto;overflow:hidden;border:1px dashed #979797;
    &:hover{border-color:#ff6f61;}
    .upload-progress{position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);}
    .file-name{display:flex;align-items:center;justify-content:center;width:100%;height:100%;padding:16px;font-size:16px;}
    /deep/ .el-upload{vertical-align:top;}
    /deep/ .el-upload-dragger{
      width:420px;height:260px;border:none;
      .ico{width:80px;height:80px;margin:30px auto 0;}
      .upload-txt{margin-top:24px;color:#ff6f61;font-size:24px;}
      .upload-tip{margin-top:16px;color:#999;}
    }
  }
</style>
